<template>
  <v-container>
    <v-row justify="center" align="center">
      <div class="text-center ma-12">
        <v-progress-circular
            rotate="270"
            size="100"
            :value="value"
            width="10"
            color="light-blue"
        >{{ restzeit }}</v-progress-circular>
      </div>
      <v-row>
        <v-col>
          <v-btn
              @click="start()"
          >Start</v-btn>
          <v-btn
              @click="stop()"
          >Stop</v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Focus",
  data: () => ({
    value: 0,
    interval: null,
    time: 25*60,
  }),
  methods: {
    start() {
      this.interval = setInterval(()=> {
        this.time = this.time - 1;
        this.value = this.timeLeft / (25*60) * 100;
        console.log('time' + this.time)
        console.log(this.timeLeft)
        console.log(this.value)
        if(this.time < 0) {
          this.stop()
        }
      }, 1000)
    },
    stop() {
      clearInterval(this.interval)
      this.value = 0
      this.time = 25*60
    },
  },
  computed: {
    timeLeft: function () {
      return (25 * 60) - this.time;
    },
    restzeit: function () {
      if(Math.floor(this.time / 60) > 0) {
        return Math.floor(this.time / 60) + ' min';
      } else {
        return (this.time % 60) + ' s';
      }

    }
  },
  beforeDestroy: function() {
    clearInterval(this.interval)
  },
}
</script>